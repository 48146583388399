$width-percentage: 90%;

@mixin match-parent {
  width: 100%;
  height: 100%;
}

@mixin safe-frame {
  width: $width-percentage;
  margin: 0 auto;
  max-width: 1300px;
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin circlfy($size) {
  width: $size;
  height: $size;
  border-radius: 50%;
  overflow: hidden;
}

@mixin squarify($size) {
  width: $size;
  height: $size;
}

@mixin roundify($size) {
  border-radius: $size;
  overflow: hidden;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-bottom {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@mixin flex-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

@mixin shadow-light {
  box-shadow: 0 3px 6px var(--shadow-one);
}

@mixin shadow-light-1 {
  box-shadow: 5px 0 20px -5px rgba(0, 0, 0, 0.08);
}

@mixin cardify {
  width: 90%;
  display: block;
  margin: 20px auto;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);
  background: var(--ion-color-light);
  border-radius: 25px;
  padding: 20px;
}

@mixin ion-match-segment($border-radius: 20px) {
  ion-segment {
    width: 100%;
    max-width: 300px;
    margin: 20px auto;
    background: var(--ion-color-light);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: $border-radius;
    color: var(--ion-color-light);
  }

  ion-segment-button {
    padding: 5px;
    font-size: 16px;
    color: var(--ion-color-primary);
    margin: 0;
  }
  .segment-button-checked {
    color: var(--ion-color-light) !important;
  }

  ion-segment-button::part(indicator) {
    padding: 0;
  }

  ion-segment-button::part(indicator-background) {
    background: var(--ion-color-primary);
    border-radius: $border-radius;
    color: var(--ion-color-light);
  }
}

@mixin super-theme-page {
  ion-header {
    --background: var(--back-base);
  }
  ion-toolbar {
    background: var(--back-base);
    --background: var(--back-base);
    --border-color: transparent;
  }
  ion-content {
    --background: #f1f5f5;
  }
}

@mixin super-list {
  ion-list {
    background: transparent;
    overflow: hidden !important;
    --border-radius: 18px;
  }

  ion-list-header {
    background: white;
    border-radius: 10px 10px 0 0;
  }

  ion-item {
    overflow: hidden;
    --background-hover: #f8f8f8;

    ion-icon {
      font-size: 18px;
      margin-right: 5px;
    }
  }

  ion-item-sliding,
  ion-item:first-child {
    --border-radius: 10px 10px 0 0;
  }
  ion-item-sliding,
  ion-item:last-child {
    --border-radius: 0 0 10px 10px;
    --border-color: transparent;
  }

  ion-item-sliding,
  ion-item:only-child {
    --border-radius: 10px 10px 10px 10px;
    --border-color: transparent;
  }

  super-button {
    ion-item-sliding,
    ion-item {
      border-radius: 0;
      --border-color: rgb(200, 199, 204);
    }
  }

  super-button:first-child ion-item {
    border-radius: 10px 10px 0 0;
  }

  super-button:last-child ion-item {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    --border-color: transparent;
  }
}

@mixin flash {
  animation: flash 2s ease-in-out infinite;
}

@mixin font-small {
  font-size: 12px;
}

@mixin font-size-medium {
  font-size: 16px;
}

@mixin font-size-large {
  font-size: 20px;
}

@mixin padding {
  padding: 10px;
}

@mixin padding-medium {
  padding: 20px;
}

@mixin padding-heavy {
  padding: 40px;
}

@mixin gradient-matt-dark {
  background: linear-gradient(131deg, #3d3d3d 0%, hsl(0, 0%, 7%) 100%);
}

@mixin gradient-matt-light {
  background: linear-gradient(131deg, #ffffff 0%, hsl(0, 0%, 95%) 100%);
}

@keyframes flash {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

@mixin ionicPadding($ps, $pe, $pt, $pb) {
  --padding-start: $ps;
  --padding-end: $pe;
  --padding-top: $pt;
  --padding-bottom: $pb;
}

// TYPOGRAPHY-------------------------------------------------------

@mixin h-small() {
  font-size: 20px;
  color: var(--shade-three);

}

@mixin h-medium() {
  font-size: 24px;
  font-weight: bold;
}

@mixin h-large() {
  font-size: 34px;
  font-weight: bold;
}

@mixin p-small() {
  font-size: 20px;
}

@mixin p-medium() {
  font-size: 20px;
}

@mixin p-large() {
  font-size: 20px;
}


// SPACING ------------------------------------------------------------------------------------


@mixin gutter($space : 15px) {
  padding-left: $space;
  padding-right: $space;
}
