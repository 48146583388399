/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one components. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import 'mixins.scss';

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

* {
  //font-family: 'Nunito', sans-serif !important;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;;

}

ion-toolbar {
  //--border-color : transparent
}

ion-back-button {
  --color: var(--shade-two);
}

.page-action-wrapper {
  width: 100%;

  super-button {
    width: 100%;
  }

  @media screen and (min-width: 1200px) {
    max-width: 400px !important;
    left: 50%;
    transform: translate(-50%, -2vh);
  }
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  @include flex-center();
  flex-direction: column;
  background: white;
  box-shadow: 0 -10px 30px -10px rgba(0, 0, 0, 0.09);
  border-radius: 33px 33px 0 0;

  button {
    width: 100%;
    background: white;
    @include roundify(12px);
    @include padding-medium();
    @include font-size-medium();
    @include flex-center();
    font-weight: bold;
  }

  .secondary {
    margin-bottom: 15px;
    border: 1px solid var(--shade-one);
  }

  .main {
    background-color: #74ebd5;
    @include gradient-matt-dark();
    color: white;
  }
}

.screen-tip {
  @include flex-center();
  flex-direction: column;
  padding: 30px 30px;

  img {
    width: 200px;
  }

  p {
    text-align: center;
    font-size: 20px;
    margin: 10px 0 20px;
    font-weight: bold;
    color: var(--shade-three);

    span {
      color: var(--shade-two);
      font-size: 14px;
    }
  }

  button {
    font-size: 16px;
    @include gradient-matt-dark();
    color: white;
    padding: 15px;
    @include flex-center();
    @include roundify(10px);
    margin: 0;
  }
}

.center {
  @include flex-center();
}

.full-width {
  width: 100%;
}

.padding-small {
  padding: 10px;
}

.padding-medium {
  padding: 20px;
}

.padding-heavy {
  padding: 30px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.circle-white-button {
  @include circlfy(35px);
  @include flex-center();
  background: white;

  ion-icon {
    font-size: 20px;
  }
}

.list-header {
  width: 100%;
  @include flex-row-between();
  padding: 0 30px;
  margin-top: 10px;

  h1 {
    margin: 0;
    font-size: 16px;
    color: var(--shade-two);
  }

  button {
    margin: 0;
    @include flex-center();
    background: transparent;
    font-size: 16px;
  }
}

.note {
  font-size: 12px;
  margin: 0 15px;
  color: var(--shade-two);
}

.spacer {
  padding: 80px;
  width: 100%;
}

.slide {
  @include match-parent();
  background: var(--back-base);

  .slide-title {
    font-weight: bold;
    margin: 10px;
  }
}

.border-bottom {
  border-bottom: 1px solid var(--shade-one);
}

.border-bottom-top {
  border-bottom: 1px solid var(--shade-one);
  border-top: 1px solid var(--shade-one);
}


h1 {
  @include h-large()
}

h2 {
  @include h-medium();
  margin: 0;
}

h3 {
  @include h-small()
}


.section {
  margin: 18px 0 10px;
  width: 100%;

  .header {
    width: 100%;
    @include gutter();
    @include flex-row-between();
    @include gutter(22px);
    padding-top: 5px;
    padding-bottom: 10px;

    .text-center {
      width: 100%;
      text-align: center;
    }

    h3 {
      margin: 0;
    }

    .toolbar {
      @include flex-row-between();

      button {
        background: transparent;
        @include roundify(5px);
        padding: 8px;
        border: none;
        @include flex-row-between();
        font-size: 14px;

      }
    }


  }

  .content {
    width: 100%;
    @include gutter();
  }
}


.empty-list-message {
  width: 100%;
  @include gutter();
  @include flex-center();
  flex-direction: column;

  ion-icon {
    @include circlfy(40px);
    padding: 20px;
    border: 1px solid var(--ion-color-dark);
  }

  h3 {

  }

  p {
    margin: 0;
    color: var(--shade-two);
  }
}

.toast-above-tab {
  transform: translateY(-70px) !important;
}

.or-label {
  width: 100%;
  border-bottom: 1px solid var(--shade-one);
  @include flex-center();
  margin: 10px 0 30px;

  p {
    margin: 0;
    background: #f1f5f5;
    padding: 10px;
    color: var(--shade-three);
    margin-bottom: -20px;
  }
}


.qid-text-logo {
  font-weight: bold;
  font-style: italic;
  font-family: 'Poppins', sans-serif !important;
}


@media screen and (min-width: 1200px) {
  ion-modal {
    --max-width: 400px !important;
    --height: 100%;
  }
}
